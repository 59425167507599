<template>
  <div style="padding:16px">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="请输入内容" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%" :height="height">
        <el-table-column label="序号" type="index" width="50" align="center"></el-table-column>

                  <el-table-column label="id" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
          <el-table-column label="收藏记录编码" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.collectCode }}</template>
          </el-table-column>
          <el-table-column label="用户编码" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.memberCode }}</template>
          </el-table-column>
          <el-table-column label="用户名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.memberName }}</template>
          </el-table-column>
          <el-table-column label="资源编码" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.resourceCode }}</template>
          </el-table-column>
          <el-table-column label="资源名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.resourceName }}</template>
          </el-table-column>
          <el-table-column label="资源类型(1:方案;2:短视频;3:vr全景)" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.resourceType }}</template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createDate }}</template>
          </el-table-column>
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @onConfirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">

            <el-col :span="12">
              <el-form-item label="id：" prop="siteName">
                <el-input v-model="form.id" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收藏记录编码：" prop="siteName">
                <el-input v-model="form.collectCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户编码：" prop="siteName">
                <el-input v-model="form.memberCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户名称：" prop="siteName">
                <el-input v-model="form.memberName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资源编码：" prop="siteName">
                <el-input v-model="form.resourceCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资源名称：" prop="siteName">
                <el-input v-model="form.resourceName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="资源类型(1:方案;2:短视频;3:vr全景)：" prop="siteName">
                <el-input v-model="form.resourceType" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：" prop="siteName">
                <el-input v-model="form.createDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {},
      showDialog: false,
      tableData: [],
      height: window.innerHeight - 256, // 表格高度
      headerHeight: 0
    }
  },

  created () {
    this.getList()
  },

  mounted () {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - this.headerHeight - 256
    },

    // 获取数据
    getList () {
	  var that = this
	  that.$http.post('/memberCollectRecords/list', that.queryInfo).then(function (response) {
        console.log(response.data.data)
        if (response.data.code == 200) {
          that.total = response.data.data.count
          that.queryInfo.currPage = response.data.data.currPage
          that.tableData = response.data.data.data
        }
      })
    },

    // 保存
    handleSave () {
	    var that = this
	  that.$http.post('/memberCollectRecords/save', that.form).then(function (response) {
		  if (response.data.code == 200) {
			  that.$notify.success({
            title: '提示',
            message: '保存成功',
            showClose: true
			  })
			 that.showDialog = false
			  that.getList()
		  } else {
			  that.$notify.info({
            title: '提示',
            message: response.data.message,
            showClose: true
			  })
		  }
      }).catch(function (error) {

      })
    },

    // 修改
    edit (row) {
      this.showDialog = true
      this.labelType = 'edit'
      this.form = JSON.parse(JSON.stringify(row))
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte (id) {
      const confirmResult = await this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch((err) => err)
      if (confirmResult === 'confirm') {
        var that = this
        that.$http.post('/memberCollectRecords/delete', { 'id': id }).then(function (response) {
          if (response.data.code == 200) {
			 that.$notify.success({
              title: '提示',
              message: '删除成功',
              showClose: true
			  })
			  that.getList()
          } else {
				 that.$notify.info({
              title: '提示',
              message: response.data.message,
              showClose: true
				  })
          }
        })
      }
    },

    // 新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true
      this.form = {}
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val
      this.getList()
    }

  }
}
</script>

<style scoped>
</style>
